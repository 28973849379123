import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./auth/login/login.component";
import { RegisterComponent } from "./auth/register/register.component";
import { ResetPasswordComponent } from "./auth/reset-password/reset-password.component";
import { ForgotPasswordComponent } from "./auth/forgot-password/forgot-password.component";
import { Register2Component } from "./auth/register2/register2.component";
import { SharedModule } from "./utils/shared/shared.module";

const routes: Routes = [
  { path: "", component: LoginComponent },
  { path: "login", component: LoginComponent },
  { path: "register", component: RegisterComponent },
  { path: "register2", component: Register2Component },
  { path: "resetPassword", component: ResetPasswordComponent },
  { path: "forgotPassword", component: ForgotPasswordComponent },
  //   { path: '', loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule)
  // },
  {
    path: "student",
    loadChildren: () =>
      import("./student/student.module").then((m) => m.StudentModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes), SharedModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
